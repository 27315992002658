.container_pg{
    display: flex;
    justify-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: #DEB887;
    color: white;
    text-align: center;
}

.divCenter{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}