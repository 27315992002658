.container_form{
    padding-right: 4%;
    padding-left: 4%;
    text-align: justify;
}

.divCenter2{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media only screen and (min-width: 335px) and (max-width: 768px){
    .divCenter2{
        top: 70%;
    }
}
@media only screen and (min-width: 769px) and (max-width: 1200px){
    .divCenter2{
        top: 50%;
    }
}
