code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html, body, body > div, .app{
  height: 100%;
  width: 100%;
  background-color: #DEB887;
  color: white;
}

@tailwind base;
@tailwind components;
@tailwind utilities;